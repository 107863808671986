import { render, staticRenderFns } from "./UsuariosCreate.vue?vue&type=template&id=4a8861de&class=sidebar-mini%20layout-fixed%20sidebar-open&"
import script from "./UsuariosCreate.vue?vue&type=script&lang=js&"
export * from "./UsuariosCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports