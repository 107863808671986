<template>
  <div class="wrapper">
    <Preloader />
    <Navbar />
    <Sidebar />
    <div class="content-wrapper" >
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Editar lead</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="#">Leads</a>
                  </li>
                  <li class="breadcrumb-item active">Editar lead</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <Content />
      </div>
    <Footer></Footer>
  </div>


</template>

<script>
import axios from "axios";
import moment from "moment";
import Footer from "@/components/layout/Footer.vue";
import Navbar from "@/components/layout/Navbar.vue";
import Preloader from "@/components/layout/Preloader.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import Content from "@/components/leads/Update.vue";


export default {
  name: "LeadUpdate",
  components: { Footer, Navbar, Preloader, Sidebar, Content,},
  data() {
    return {
      email: "",
      nome: "",
      responsavel: "",
      numero: "",
      rua: "",
      bairro: "",
      cidade: "",
      uf: "",
      cep: "",
      telefone: "",
      segmento: "",
      whatsapp: "",
      complemento: "",
      selectvalue: false,
      select: "",
      response: "",
      state: "",
      stateComentario: "",
      comentario: "",
      id: "",
      user: "",
      comentarios: "",
      idLead: "",
      user_id: "",
    };
  },
  watch: {
    selectvalue() {
      if (this.selectvalue == 0) {
        this.select = "btn btn-secondary";
      }
      if (
        this.selectvalue == 1 ||
        this.selectvalue == 2 ||
        this.selectvalue == 3
      ) {
        this.select = "btn btn-warning";
      }
      if (this.selectvalue == 4) {
        this.select = "btn btn-danger";
      }
      if (this.selectvalue == 5 || this.selectvalue == 6) {
        this.select = "btn btn-primary";
      }
      if (this.selectvalue == 7) {
        this.select = "btn btn-success";
      }
      if (this.selectvalue == 8) {
        this.select = "btn btn-outline-success";
      }
      if (this.selectvalue == 9) {
        this.select = "btn btn-info";
      }
      if (this.selectvalue == 10) {
        this.select = "btn btn-dark";
      }
      // console.log(this.select);
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
  methods: {
    return() {
      if (this.response.status == "success" && this.response.status != "") {
        this.state = true;
      } else {
        this.state = false;
      }
    },
    before() {
      // console.log(this.user_id);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(
          `${this.$store.state.base_url}lead/${this.$router.history.current.params.id}`,
          { id_parceiro: this.user_id }
        )
        .then((response) => {
          // console.log(this.id);
          // console.log(response.data.lead);
          this.email = response.data.lead.email;
          this.id = response.data.lead.id_parceiro;
          this.nome = response.data.lead.nome_empresa;
          this.responsavel = response.data.lead.nome_responsavel;
          this.numero = response.data.lead.numero;
          this.rua = response.data.lead.rua;
          this.bairro = response.data.lead.bairro;
          this.cidade = response.data.lead.cidade;
          this.uf = response.data.lead.uf;
          this.cep = response.data.lead.cep;
          this.telefone = response.data.lead.telefone;
          this.segmento = response.data.lead.segmento;
          this.whatsapp = response.data.lead.whatsapp;
          this.complemento = response.data.lead.complemento;
          this.selectvalue = parseInt(response.data.lead.status_lead);
          // this.comentario = response.data.lead.observacao;

          // console.log(this.selectvalue);
        });
    },
    create() {
      // console.log("teste");
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");

      axios
        .put(
          `${this.$store.state.base_url}leads/${this.$router.history.current.params.id}`,
          {
            email: this.email,
            nome_empresa: this.nome,
            nome_responsavel: this.responsavel,
            segmento: this.segmento,
            rua: this.rua,
            numero: this.numero,
            whatsapp: this.whatsapp,
            complemento: this.complemento,
            bairro: this.bairro,
            cidade: this.cidade,
            uf: this.uf,
            cep: this.cep,
            telefone: this.telefone,
            status: 1,
            excluido: 0,
            status_lead: this.selectvalue,
            tipo: 1,
            hash: this.hash,
            // observacao: this.comentario,
            // checked: this.checked
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.status == 201) {
            this.state = true;
          }
          // this.$router.push("/leads");
        });
      this.return();
    },
    voltar() {
      this.$router.push("/leads");
    },
    comentariosList() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Access-Control-Allow-Methods"] =
        "GET, POST, PUT, DELETE, PATCH, OPTIONS";
      axios.defaults.headers.common["Access-Control-Allow-Headers"] =
        "Origin, Content-Type, Accept, Authorization, X-Requested-With, X-CSRF-Token";
      axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
      axios.defaults.headers.common["Access-Control-Max-Age"] = "1728000";

      axios
        .post(`${this.$store.state.base_url}comentarios`, {
          id_lead: this.idLead,
        })
        .then((response) => {
          this.comentarios = response.data.reverse();
          // console.log(response);
        });
    },
    comentarioSend() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}comentarios/store`, {
          id_lead: this.idLead,
          comentarios: this.comentario,
          status_lead: this.selectvalue,
        })
        .then((response) => {
          this.comentarios = response.data.comentarios;
          this.stateComentario = true;
          // console.log(response);
          this.comentariosList();
        });
    },
    deleteComentario(id) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}comentarios/delete`, {
          id: id,
        })
        .then((response) => {
          this.comentarios = response.data.comentarios;
          // console.log(response);
          this.comentariosList();
        });
    },
    gerar(id) {
      this.$router.push(`/contratos/${id}`);
    },
    moment: function (date) {
      moment.locale("pt-br");
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
  created() {
    this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
    this.user_id = this.user.id;
    this.idLead = this.$router.history.current.params.id;
  },
  mounted() {
    this.before();
    this.comentariosList();
  },
};
</script>

<style scoped>
#btn2 {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}
.contentform {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  /* margin: 50px;; */
  max-width: 800px;
  width: 100%;
}
#main {
  display: flex;
  flex-direction: column;
  margin: -20px 5px 5px 5px;
}
#selectform {
  margin-top: 30px;
  width: 100%;
}
.alert {
  z-index: -1;
  margin-top: 50px;
}
.msg_alert {
  display: flex;
  justify-content: center;
  align-items: center;
}
#text-area {
  width: 100%;
}
#table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200px;
}
.inputState {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  border-radius: 0px;
}
.alert-comentario {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c3e6cb;
  padding: 10px;
}
.nav-link {
  z-index: 0;
}
#form-tab {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
#nav-tabContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 40px 0px;
}
.comentario-td {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  width: 100%;
  flex-wrap: wrap;
  max-width: 800px;
  height: auto;
}
.button2 {
  /* margin-left: 50px */
  margin: 20px;
}
.content-wrapper {
  height: auto;
}
#text-area {
  display: flex;
  flex-wrap: wrap;
}
.td-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
    
