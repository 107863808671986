<template>
  <div>
    <Preloader />
    <body class="login-page" style="min-height: 564.391px; background: rgb(0, 175, 239);">
    <div class="login-box">

        <div class="card card-outline card-primary">
            <div class="row">
                <div class="col-md-6">

                </div>

            </div>
            <div class="card-header text-center">
                <img src="../../assets/fotos/logo.jpeg" class="img-fluid">
            </div>
            <div class="card-body">
                <p class="h2 text-center">e-Parceiro</p>
                <div v-if="msg" class="alert alert-danger alert">Senha ou Usuário incorretos</div>
                <div v-on:keyup.enter="login()">
                    <div class="input-group mb-3">
                        <input type="email" class="form-control form-control-sm" placeholder="Digite seu e-mail" v-model="email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" class="form-control form-control-sm" placeholder="Digite sua senha" v-model="password">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <div class="icheck-primary">
                                <input type="checkbox" id="remember">
                                <label for="remember">Lembrar-me
                                </label>
                            </div>
                        </div>

                        <div class="col-4">
                            <button @click="login()" class="btn btn-primary btn-block btn-sm">Entrar</button>
                        </div>

                    </div>
                  </div>




            </div>

        </div>

    </div>

    <div id="forest-ext-shadow-host"></div>


</body>
  </div>
</template>

<script>
import Preloader from "@/components/layout/Preloader.vue";
import axios from "axios";
export default {
  name: "TheLogin",
  data() {
    return {
      email: "",
      password: "",
      msg: "",
    };
  },
  components: { Preloader },
  methods: {
    login() {
      axios
        .post(this.$store.state.url_login, {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          // this.$store.commit("setAut", true);
          console.log(response);
          sessionStorage.setItem("token", response.data.token);
       
          this.$router.push("/leads");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 403) {
            this.msg = "Senha ou Usuário incorretos";
          }
          this.$router.push("/login");
        });
    },
  },
};
</script>

<style scoped>
.index-login {
  margin-bottom: 200px;
}
.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.login-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.btn-block {
  width: 100%;
  margin: 0.5rem;
}
</style>