<template>
  <div>
    <div class="small-box bg-danger">
      <div class="inner">
        <h3>
          <span>{{ saldo | currency }}</span>
        </h3>
        <p>Saldo Pendente</p>
      </div>
      <div class="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          fill="currentColor"
          class="bi bi-cash-coin icon"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
          />
          <path
            d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"
          />
          <path
            d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"
          />
          <path
            d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "SaldoPedente",
  components: {
    // HelloWorld,
  },

  data: () => ({
    value: [423, 446, 675, 510, 590, 610, 0],
    idUser: "",
    count: 0,
    total: 0,
    bonus: 0,
    totalbonus: 0,
    saldo: 0,
  }),
  filters: {
    currency(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  methods: {
    getTotal() {
      this.idUser = JSON.parse(window.atob(sessionStorage.getItem("session")));
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}contratos/pendente`, {
          idUser: this.idUser.id,
        })
        .then((response) => {
          // this.value = response.data;
          // console.log(response);
          this.count = response.data;
          this.count.forEach((element) => {
            this.total += element.valor_contrato;
            // console.log(this.total);
            //   return 1;
          });
          // console.log(this.count);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    main: function () {
      this.idUser = JSON.parse(window.atob(sessionStorage.getItem("session")));
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}contratos/pendente`, {
          idUser: this.idUser.id,
        })
        .then((response) => {

          this.count = response.data;
          this.count.forEach(
            (element) => (this.total += element.valor_contrato)
          );
       
          this.bonus = JSON.parse(
            window.atob(sessionStorage.getItem("session"))
          );
          this.bonus = this.bonus.porcentagem_bonus;
          
          this.totalbonus = this.bonus / 100;
          this.saldo = this.total * this.totalbonus;
          console.log(this.saldo);
        })
        .catch((error) => {
          console.log(error);
        });

      // console.log(this.bonus);
      // console.log(this.totalbonus);
    },
  },

  created() {
    setTimeout(() => {
      this.main();
    }, 2000);
  },
  mounted() {
    // this.list();
  },
};
</script>
<style scoped>
.modalvalor {
  display: flex;
  flex-direction: row;
  width: 100px;
  height: 50px;
}
.icon {
  color: rgba(0, 0, 0, 0.15);
}
</style>