var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"form_gestor"}},[_c('div',{attrs:{"id":"form"}},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputEmail4"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",attrs:{"type":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputEmail4"}},[_vm._v("nome")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nome),expression:"nome"}],staticClass:"form-control",attrs:{"type":"text","id":"inputEmail4"},domProps:{"value":(_vm.nome)},on:{"input":function($event){if($event.target.composing)return;_vm.nome=$event.target.value}}})]),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputPassword4"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.senha),expression:"senha"}],staticClass:"form-control",attrs:{"type":"password","id":"inputPassword4"},domProps:{"value":(_vm.senha)},on:{"input":function($event){if($event.target.composing)return;_vm.senha=$event.target.value}}})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{attrs:{"id":"btn"}},[_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":_vm.voltar}},[_vm._v("Voltar")]),_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.create}},[_vm._v("Cadastrar")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputAddress"}},[_vm._v("Address")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"inputAddress","placeholder":"1234 Main St"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputAddress2"}},[_vm._v("Address 2")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"inputAddress2","placeholder":"Apartment, studio, or floor"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputCity"}},[_vm._v("City")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"inputCity"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputState"}},[_vm._v("State")]),_c('select',{staticClass:"form-select",attrs:{"id":"inputState"}},[_c('option',{attrs:{"selected":""}},[_vm._v("Choose...")]),_c('option',[_vm._v("...")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"inputZip"}},[_vm._v("Zip")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"inputZip"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","id":"gridCheck"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"gridCheck"}},[_vm._v(" Check me out ")])])])
}]

export { render, staticRenderFns }