<template>
  <div id="form_gestor">
    <div id="form">
      <div class="col-md-12">
        <label for="inputEmail4" class="form-label">Email</label>
        <input type="email" class="form-control" v-model="email"/>
      </div>
      <div class="col-md-12">
        <label for="inputEmail4" class="form-label">nome</label>
        <input type="text" class="form-control" id="inputEmail4"  v-model="nome" />
      </div>
      <div class="col-md-12">
        <label for="inputPassword4" class="form-label">Password</label>
        <input type="password" class="form-control" id="inputPassword4"  v-model="senha"/>
      </div>
      <div class="col-12">
        <label for="inputAddress" class="form-label">Address</label>
        <input
          type="text"
          class="form-control"
          id="inputAddress"
          placeholder="1234 Main St"
        />
      </div>
      <div class="col-12">
        <label for="inputAddress2" class="form-label">Address 2</label>
        <input
          type="text"
          class="form-control"
          id="inputAddress2"
          placeholder="Apartment, studio, or floor"
        />
      </div>
      <div class="col-md-12">
        <label for="inputCity" class="form-label">City</label>
        <input type="text" class="form-control" id="inputCity" />
      </div>
      <div class="col-md-12">
        <label for="inputState" class="form-label">State</label>
        <select id="inputState" class="form-select">
          <option selected>Choose...</option>
          <option>...</option>
        </select>
      </div>
      <div class="col-md-12">
        <label for="inputZip" class="form-label">Zip</label>
        <input type="text" class="form-control" id="inputZip" />
      </div>
      <div class="col-12">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="gridCheck" />
          <label class="form-check-label" for="gridCheck"> Check me out </label>
        </div>
      </div>

    </div>
    <div id="btn">
     <button type="button"  @click="voltar" class="btn btn-danger">Voltar</button>
     <button type="button"  @click="create" class="btn btn-success">Cadastrar</button>
    </div>
 
  </div>
</template>

<script>
import axios from "axios";
export default {
    name: 'FormGestor',
     data () {
        return {
        email :'',
        senha :'',
          nome: '',
          response: '',
          error: false,
          success: false,
          tipo: 2,
          user: ""
        
        
        // lista: ''
        }
    },
    methods: {
       create() {
      // console.log(this.email);
      // console.log(this.senha);
      this.hash = sessionStorage.getItem("token");
        axios.post(this.$store.state.base_url + "parceiros", {
          email: this.email,
          password: this.senha,
          name: this.nome,
          parceiro_id: this.user.id,
          tipo: this.tipo,
          status: 1,
        })
        .then(response => {
          if (response.data.status == "error") {
            this.response = response.data.erros;
            this.error = true;
            this.success = false;
          } else {
            this.response = response.data.message;
            this.error = false;
            this.success = true;
          }
          console.log(response)
         
        })
     
    
    },
    voltar(){
      this.$router.push('/usuarios')
    }
    },
    created(){
       this.user = JSON.parse(sessionStorage.getItem("session"));
    }

}
</script>

<style scoped>
#form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 30px;

}
#form_gestor {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
#btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}
button {
  margin: 10px;
}
</style>