<template>
<div>
  <div id="modal-btn">
    <button class="btn btn-success" @click="$router.push('usuarios/create')">
        <span>Cadastrar</span>
        <i class="fas fa-plus"></i>
    </button>
  </div>
  <table class="table table-bordered table-hover">
    <!-- {{lista.data}} -->
    <thead>
      <tr>
        <th class="text-left">#</th>
        <th class="text-left">Name</th>
        <th class="text-left">Email</th>
        <th class="text-left">Data de cadastro</th>
        <th style="width: 90px">Editar</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in lista.data" :key="item.id">
        <td>{{ item.id }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.email }}</td>
        <td>{{ moment(item.created_at) }}</td>
        <td>
          <button @click="editar(item.id)" type="button" class="btn btn-light">
            Editar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "Table_",
  data() {
    return {
      desserts: [],
      lista: [],
      // item: "",
      id: "",
      idUser: "",
      user: "",
      time: "",
    };
  },
  methods: {
    List() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios.defaults.headers.common["user"] = this.$store.state.idUser;
      axios
        .post(`${this.$store.state.base_url}parceiros/user`, {
          user: this.user.id,
        })
        .then((response) => {
          this.lista = response;
        //   console.log(response);
        });
    },
    editar(id) {
      // console.log(id)
      this.$router.push(`/usuarios/update/${id}`);
      },
     moment: function (date) {
      moment.locale("pt-br");
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
  created() {
    // this.List();
    this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
  },
  mounted() {
    // this.time = setInterval(() => {
    this.List();
    // }, 5000);
  },
  beforeMount() {
    // this.$router.go(2)
    // this.List();
  },
};
</script>
<style scoped>
#modal-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.fa-plus {
margin-left: 5px;
}
</style>