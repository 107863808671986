<template>
  <section class="content">
    <div v-if="this.state" class="alert alert-success alert">
      <span class="msg_alert">Atualizado com sucesso</span>
    </div>
    <div v-if="this.erro" class="alert alert-success alert">
      <span class="msg_alert"
        >[ERROR] tente novamente mais tarde ou tente recarregar a página</span
      >
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="card card-widget">
            <div class="card-header">
              <div class="user-block">
                <h3 class="card-title">Anotações</h3>
              </div>

              <div class="card-tools">
                <button type="button" class="btn btn-tool" title="Mark as read">
                  <i class="far fa-circle"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i class="fas fa-minus"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="remove"
                >
                </button>
              </div>
            </div>

            <div class="card-body" style="display: block">
              <div class="col-md-6" v-if="selectvalue < 11">
                <div class="form-group">
                  <label for="status" title="Para gerar o contrato, o status deve ser igual a Venda Realizada"
                    >Status <svg title="Para gerar o contrato, o status deve ser igual a Venda Realizada" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
</svg></label>
                  <select
                    id="status"
                    class="form-select form-control-sm"
                    title="Para gerar o contrato, o status deve ser igual a Venda Realizada"
                    v-model="selectvalue"
                    @click="create()"
                  >
                    <option active value="0">Novo Lead</option>
                    <option value="1">Primeiro Contato</option>
                    <option value="2">Segundo Contato</option>
                    <option value="3">Terceiro Contato</option>
                    <option value="4">Reunião Agendada</option>
                    <option value="5">Aguardando Proposta</option>
                    <option value="6">Proposta Enviada</option>
                    <!-- <option value="7">Gerar Contrato</option> -->
                    <option value="8">Venda Realizada</option>
                    <option value="9">Interesse Futuro</option>
                    <option value="10">Não Responde</option>
                  </select>
                </div>
              </div>
              <p>
                Este espaço é dedicado para você deixar anotações sobre o lead.
              </p>
            </div>

            <div class="card-footer" style="display: block">
              <form v-on:submit.prevent="comentarioSend()">
                <div class="img-push">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Digite seu comentário"
                    v-model="comentario"
                    maxlength="255"
                    minlength="1"
                    title="Tamanho máximo de 255 caracteres"
                  />
                </div>
              </form>
            </div>
            <div
              class="card-footer card-comments"
              style="display: block"
              v-for="(comentario, index) in comentarios"
              :key="index"
            >
              <div class="card-comment ml-1" v-if="stateComentario">
                <div class="comment-text">
                  <div class="d-flex justify-content-end align-items-end">
                    <span
                      v-if="comentario.status_lead == 0"
                      class="badge"
                      style="background-color: #6c757d"
                    >
                      Novo Lead
                    </span>
                    <span
                      v-if="comentario.status_lead == 1"
                      class="badge"
                      style="background-color: #ffc107"
                    >
                      Primeiro Contato
                    </span>
                    <span
                      v-if="comentario.status_lead == 2"
                      class="badge"
                      style="background-color: #ffc107"
                    >
                      Segundo Contato
                    </span>
                    <span
                      v-if="comentario.status_lead == 3"
                      class="badge"
                      style="background-color: #ffc107"
                    >
                      Terceiro Contato
                    </span>
                    <span
                      v-if="comentario.status_lead == 4"
                      class="badge"
                      style="background-color: #dc3545"
                    >
                      Reunião Agendada
                    </span>
                    <span
                      v-if="comentario.status_lead == 5"
                      class="badge"
                      style="background-color: #007bff"
                    >
                      Aguardando Proposta
                    </span>
                    <span
                      v-if="comentario.status_lead == 6"
                      class="badge"
                      style="background-color: #007bff"
                    >
                      Proposta Enviada
                    </span>
                    <!-- <span
                      v-if="comentario.status_lead == 7"
                      class="badge"
                      style="background-color: #28a745"
                    >
                      Gerar Contrato
                    </span> -->
                    <span
                      v-if="comentario.status_lead == 8"
                      class="badge"
                      style="background-color: #28a745"
                    >
                      Venda Realizada
                    </span>
                    <span
                      v-if="comentario.status_lead == 9"
                      class="badge"
                      style="background-color: #6c757d"
                    >
                      Interesse Futuro
                    </span>
                    <span
                      v-if="comentario.status_lead == 10"
                      class="badge"
                      style="background-color: #6c757d"
                    >
                      Não Responde
                    </span>
                    <span
                      v-if="comentario.status_lead == 11"
                      class="badge"
                      style="background-color: #28a745"
                    >
                      Contrato Gerado
                    </span>
                    <span
                      v-if="comentario.status_lead == 12"
                      class="badge"
                      style="background-color: #6c757d"
                    >
                      Finalizado
                    </span>
                  </div>
                  <span class="username"
                    >{{ user.name }}
                    <span class="text-muted float-right">{{
                      moment(comentario.created_at)
                    }}</span> </span
                  >{{ comentario.comentarios }}
                </div>
              </div>
              <b-skeleton v-else></b-skeleton>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Dados do lead</h3>
            </div>

            <form @submit.prevent="" class="row g-3 needs-validation">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="nome">Nome</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="nome"
                        placeholder="Digite o nome"
                        required
                        v-model="nome"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">E-mail</label>
                      <input
                        type="email"
                        class="form-control form-control-sm"
                        id="email"
                        placeholder="Digite o e-mail"
                        required
                        v-model="email"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="responsavel">Responsável</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="responsavel"
                        placeholder="Digite o nome do responsável"
                        v-model="responsavel"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="segmento">Segmento</label>
                      <select
                        class="form-select form-control-sm"
                        id="segmento"
                        v-model="segmento"
                      >
                        <option value="">Selecione</option>
                        <option value="Acessórios para tecnologia">
                          Acessórios para tecnologia
                        </option>
                        <option value="Adulto">Adulto</option>
                        <option value="Alimentícios">Alimentícios</option>
                        <option value="Antiguidades e coleções">
                          Antiguidades e coleções
                        </option>
                        <option value="Artigos religiosos">
                          Artigos religiosos
                        </option>
                        <option value="Autos e peças">Autos e peças</option>
                        <option value="Bebês">Bebês</option>
                        <option value="Beleza e cuidados especiais">
                          Beleza e cuidados especiais
                        </option>
                        <option value="Brinquedas e hobbies">
                          Brinquedas e hobbies
                        </option>
                        <option value="Cursos">Cursos</option>
                        <option value="Decoração">Decoração</option>
                        <option value="Esporte fitness">Esporte fitness</option>
                        <option value="Eletrodomésticos">
                          Eletrodomésticos
                        </option>
                        <option value="Esporte e lazer">Esporte e lazer</option>
                        <option value="Eletrônicos e celulares">
                          Eletrônicos e celulares
                        </option>
                        <option value="Farmácia">Farmácia</option>
                        <option value="Festas e lembrancinhas">
                          Festas e lembrancinhas
                        </option>
                        <option value="Ferramentas e equipamentos">
                          Ferramentas e equipamentos
                        </option>
                        <option value="Ingressos">Ingressos</option>
                        <option value="Imóveis">Imóveis</option>
                        <option value="Instrumentos musicais">
                          Instrumentos musicais
                        </option>
                        <option value="Jardim">Jardim</option>
                        <option value="Jóias e relógios">
                          Jóias e relógios
                        </option>
                        <option value="Livros, revistas e commics">
                          Livros, revistas e commics
                        </option>
                        <option value="Máquinas e equipamentos agrícolas">
                          Máquinas e equipamentos agrícolas
                        </option>
                        <option value="Material construção">
                          Material construção
                        </option>
                        <option value="Moda">Moda</option>
                        <option value="Papelaria">Papelaria</option>
                        <option value="Petshop">Petshop</option>
                        <option value="Produtos e equipamentos de limpeza">
                          Produtos e equipamentos de limpeza
                        </option>
                        <option value="Serviços">Serviços</option>
                        <option value="Téxtil">Téxtil</option>
                        <option value="Ótica">Ótica</option>
                        <option value="Saúde">Saúde</option>
                        <option value="Geral">Geral</option>
                        <option value="Outros">Outros</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="telefone">Telefone</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="telefone"
                        placeholder="Digite o número de telefone"
                        v-model="telefone"
                        required
                        v-mask="'(##) #####-####'"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="whatsapp">Whatsapp</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="whatsapp"
                        placeholder="Digite o número do whatsapp"
                        v-model="whatsapp"
                        v-mask="'(##) #####-####'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="cep">CEP</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="cep"
                        placeholder="Digite o CEP"
                        v-model="cep"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="rua">Rua</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="rua"
                        placeholder="Digite a rua"
                        v-model="rua"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="numero">Nº</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="numero"
                        placeholder="Digite o número"
                        v-model="numero"
                      />
                    </div>
                  </div>
                  <!--  -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="complemento">Complemento</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="complemento"
                        placeholder="Complemento"
                        v-model="complemento"
                      />
                    </div>
                  </div>
                  

                  <!--  -->
                </div>
                <div class="row">
                  <!-- bairro -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="bairro">Bairro</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="bairro"
                        placeholder="Digite o Bairro"
                        v-model="bairro"
                      />
                    </div>
                  </div>

                  <!-- end bairro -->
                 
                  <!-- cidade -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="cidade">Cidade</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="cidade"
                        placeholder="Digite a cidade"
                        v-model="cidade"
                      />
                    </div>
                  </div>

                  <!--  -->
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="uf">UF</label>
                      <select
                        id="uf"
                        class="form-select form-control-sm"
                        v-model="estado"
                      >
                        <option value="">Selecione</option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espirito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-header">
                <button
                  type="submit"
                  class="btn btn-success btn-sm"
                  v-if="selectvalue == 8"
                  @click="gerar($router.history.current.params.id)"
                >
                  Gerar contrato
                </button>
                <button
                  type="submit"
                  class="btn btn-primary ml-1 btn-sm"
                  @click="create()"
                >
                  Salvar
                </button>
                <span class="float-right">{{ moment(update_at) }}</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "ContentUpdate",
  data() {
    return {
      email: "",
      nome: "",
      responsavel: "",
      numero: "",
      rua: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      telefone: "",
      segmento: "",
      whatsapp: "",
      complemento: "",
      selectvalue: false,
      select: "",
      response: "",
      state: "",
      stateComentario: false,
      comentario: "",
      id: "",
      user: "",
      comentarios: "",
      idLead: "",
      user_id: "",
      erro: false,
      update_at: "",
    };
  },
  // watch: {
  //   selectvalue() {
  //     if (this.selectvalue == 0) {
  //       this.select = "btn btn-secondary";
  //     }
  //     if (
  //       this.selectvalue == 1 ||
  //       this.selectvalue == 2 ||
  //       this.selectvalue == 3
  //     ) {
  //       this.select = "btn btn-warning";
  //     }
  //     if (this.selectvalue == 4) {
  //       this.select = "btn btn-danger";
  //     }
  //     if (this.selectvalue == 5 || this.selectvalue == 6) {
  //       this.select = "btn btn-primary";
  //     }
  //     if (this.selectvalue == 7) {
  //       this.select = "btn btn-success";
  //     }
  //     if (this.selectvalue == 8) {
  //       this.select = "btn btn-outline-success";
  //     }
  //     if (this.selectvalue == 9) {
  //       this.select = "btn btn-info";
  //     }
  //     if (this.selectvalue == 10) {
  //       this.select = "btn btn-dark";
  //     }
  //     // console.log(this.select);
  //   },
  // },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
  watch: {
    cep() {
      if (this.cep.length == 8) {
        axios
          .get(`https://viacep.com.br/ws/${this.cep}/json/`)
          .then((response) => {
            this.rua = response.data.logradouro;
            this.bairro = response.data.bairro;
            this.cidade = response.data.localidade;
            this.uf = response.data.uf;
          });
      }
    },
  },
  methods: {
    return() {
      if (this.response.status == "success" && this.response.status != "") {
        this.state = true;
      } else {
        this.state = false;
      }
    },
    before() {
      // console.log(this.user_id);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(
          `${this.$store.state.base_url}lead/${this.$router.history.current.params.id}`,
          { id_parceiro: this.user_id }
        )
        .then((response) => {
          // console.log(this.id);
          console.log(response.data.lead);
          this.email = response.data.lead.email;
          this.id = response.data.lead.id_parceiro;
          this.nome = response.data.lead.nome_empresa;
          this.responsavel = response.data.lead.nome_responsavel;
          this.numero = response.data.lead.numero;
          this.rua = response.data.lead.rua;
          this.bairro = response.data.lead.bairro;
          this.cidade = response.data.lead.cidade;
          this.estado = response.data.lead.estado;
          this.cep = response.data.lead.cep;
          this.telefone = response.data.lead.telefone;
          this.segmento = response.data.lead.segmento;
          this.whatsapp = response.data.lead.whatsapp;
          this.complemento = response.data.lead.complemento;
          this.selectvalue = parseInt(response.data.lead.status_lead);
          this.update_at = response.data.lead.updated_at;
          // this.comentario = response.data.lead.observacao;

          // console.log(this.selectvalue);
        });
    },
    create() {
      console.log(this.estado);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");

      axios
        .put(
          `${this.$store.state.base_url}leads/${this.$router.history.current.params.id}`,
          {
            email: this.email,
            nome_empresa: this.nome,
            nome_responsavel: this.responsavel,
            segmento: this.segmento,
            rua: this.rua,
            numero: this.numero,
            whatsapp: this.whatsapp,
            complemento: this.complemento,
            bairro: this.bairro,
            cidade: this.cidade,
            estado: this.estado,
            cep: this.cep,
            telefone: this.telefone,
            status: 1,
            excluido: 0,
            status_lead: this.selectvalue,
            tipo: 1,
            hash: this.hash,
          }
        )
        .then((response) => {
          console.log(response);
          this.state = true;
        })
        .catch((error) => {
          console.log(error);
          this.erro = true;
        });
      this.return();
    },
    voltar() {
      this.$router.push("/leads");
    },
    comentariosList() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Access-Control-Allow-Methods"] =
        "GET, POST, PUT, DELETE, PATCH, OPTIONS";
      axios.defaults.headers.common["Access-Control-Allow-Headers"] =
        "Origin, Content-Type, Accept, Authorization, X-Requested-With, X-CSRF-Token";
      axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
      axios.defaults.headers.common["Access-Control-Max-Age"] = "1728000";

      axios
        .post(`${this.$store.state.base_url}comentarios`, {
          id_lead: this.idLead,
        })
        .then((response) => {
          this.comentarios = response.data.reverse();
          this.stateComentario = true;
          console.log(response);
        });
    },
    async comentarioSend() {
      this.stateComentario = false;
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}comentarios/store`, {
          id_lead: this.idLead,
          comentarios: this.comentario,
          status_lead: this.selectvalue,
        })
        .then((response) => {
          this.comentarios = response.data.comentarios;
          // console.log(response);
          this.stateComentario = true;
          this.comentario = "";
          this.comentariosList();
        })
        .catch((error) => {
          console.log(error);
          this.stateComentario = true;
        });
    },
    deleteComentario(id) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}comentarios/delete`, {
          id: id,
        })
        .then((response) => {
          this.comentarios = response.data.comentarios;
          // console.log(response);
          this.comentariosList();
        });
    },
    gerar(id) {
      this.$router.push(`/contratos/${id}`);
    },
    moment: function (date) {
      moment.locale("pt-br");
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    getCep: function () {
      axios
        .get(`https://viacep.com.br/ws/${this.cep}/json/`)
        .then((response) => {
          this.rua = response.data.logradouro;
          this.bairro = response.data.bairro;
          this.cidade = response.data.localidade;
          this.uf = response.data.uf;
        });
    },
  },
  created() {
    this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
    this.user_id = this.user.id;
    this.idLead = this.$router.history.current.params.id;
  },
  mounted() {
    this.before();
    this.comentariosList();
  },
};
</script>

<style scoped>
#main-update {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: row; */
}
.contentform {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 600px;
  width: 100%;
  padding: 30px;
}
.widgets {
  /* display: grid; */
  /* grid-gap: 10px; */
  /* margin: 10px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 500px;
  height: 610px;
  overflow: scroll;
  /* margin-bottom: 120px; */
  width: 100%;
}
.div-btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.alert {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
}
.salvar {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 20px;
}
.contrato {
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
  #main-update {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* padding: 0 20px; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    height: 100%;
  }
  .contentform {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    max-width: 600px;
    padding: 30px;
  }
}
.container {
  margin-top: 4em;
  margin-bottom: 4em;
}
</style>
