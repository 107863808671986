<template>
  <div id="sidebar">
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a class="brand-link">
        <!-- <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8"> -->
        <span class="brand-text font-weight-light"><b>E</b>-Parceiros</span>
      </a>

      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">
            <!-- <img src="dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image"> -->
          </div>
          <div class="info">
            <a class="d-block"><b>Olá, </b> {{ name }}</a>
          </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
            <!-- <li v-if="user.tipo != 3" class="nav-item">
              <router-link to="/usuarios" class="nav-link">
                <i class="nav-icon fas fa-user"></i>
                <p class="text">Usuários</p>
              </router-link>
            </li> -->
            <li class="nav-item">
              <router-link to="/leads" class="nav-link">
                <i class="nav-icon fas fa-th"></i>
                <p class="text">Dashboard</p>
              </router-link>
            </li>

            <li class="nav-item">
              <a @click="exit()" class="nav-link">
                <i class="nav-icon far fa-circle text-danger"></i>
                <p class="text">Sair</p>
              </a>
            </li>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
  </div>
</template>

<script>
export default {
  name: "TheSidebar",
  data() {
    return {
      aut: "",
      name: "",
      user: "",
    };
  },
  methods: {
    exit() {
      // this.$store.commit("setAut", false);
      // this.$store.commit("setNameUser", "");
      // this.$store.commit("setTipo", "");
      // this.$store.commit("setIdUser", "");
      // sessionStorage.removeItem("itemName");
      sessionStorage.clear();
      this.$router.push("/login");
    },
    list: function () {
      this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
      this.name = this.user.name;
    },
  },
  created() {
    setTimeout(() => {
      this.list();
    }, 1000);
    // this.list();
  },
};
</script>

<style scoped>
#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
  height: 100%;
  z-index: 20;
  /* background-color: #fff; */
  /* overflow: auto; */
}
.brand-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>