<template>
  <div>
    <div class="small-box bg-warning">
      <div class="inner">
        <h3>
          <span
            ><span>{{ count }}</span></span
          >
        </h3>

        <p>Contratos Gerados</p>
      </div>
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
  <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
  <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
</svg>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
export default {
  name: "BoardValor",

  components: {
    // HelloWorld,
  },

  data: () => ({
    value: [423, 446, 675, 510, 590, 610, 0],
    idUser: "",
    count: 0,
    total: 0,
  }),
  methods: {
    getTotal() {
      this.idUser = JSON.parse(window.atob(sessionStorage.getItem("session")));
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}contratos/pendente`, {
          idUser: this.idUser.id,
        })
        .then((response) => {
          // this.value = response.data;
          console.log(response);
          this.count = response.data.length;

          // this.count.forEach((element) => {
          //   this.total += element.valor_contrato;
          // });

          // console.log(this.count);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    // this.getTotal();
    setTimeout(() => {
      this.getTotal();
    }, 1000);
  },
  mounted() {
    // this.list();
  },
};
</script>
<style scoped>
.modalvalor {
  display: flex;
  flex-direction: row;
  width: 100px;
  height: 50px;
}
.icon {
  color: rgba(0, 0, 0, 0.15);
}
.inner {
  color: white;
}
</style>