<template>
  <div id="main">
    <div>
      <div id="form_users">
        <FormADM v-if="tipo == 1"></FormADM>
        <FormGestor v-if="tipo == 2"></FormGestor>
        <FormParceiro v-if="tipo == 3"></FormParceiro>
        <FormFINANCEIRO v-if="tipo == 4"></FormFINANCEIRO>
      </div>
      <!-- 
    <button @click="create()" id="btn" type="button" class="btn btn-dark">
      Cadastrar
    </button> -->
    </div>
  </div>
</template>

<script>
import FormParceiro from "@/components/usuarios/FormParceiro.vue";
import FormADM from "@/components/usuarios/FormADM.vue";
import axios from "axios";
import FormFINANCEIRO from "@/components/usuarios/FormFINANCEIRO.vue";
import FormGestor from "@/components/usuarios/FormGestor.vue";
export default {
  name: "UsuariosUpdate",

  components: {
    // HelloWorld,
    // BoardHome
    FormParceiro,
    FormADM,
    FormGestor,
    // Table,
    FormFINANCEIRO,
  },

  data: () => ({
    drawer: false,
    group: null,
    email: "",
    senha: "",
    response: "",
    hash: "",
    auth: false,
    tipo: "",
    admin: false,
    user: [],
  }),

  methods: {
   List: function () {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .get(
          `${this.$store.state.base_url}parceiro/${this.$router.history.current.params.id}`
        )
        .then((response) => {
          console.log(response);
        //   this.email = response.data.email;
        //   this.senha = response.data.senha;
        //   this.nome = response.data.name;
        //   this.status = parseInt(response.data.status);
          this.tipo = parseInt(response.data.tipo);
        });
    },
    create() {
      console.log("teste");
      this.$router.push("/usuarios/create");
    },
  },
    created() {
    this.List();
    this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
    // console.log(this.user.tipo);
    // this.tipo = this.user.tipo;
  },
  watch: {
    admin() {
      if (this.$store.state.tipo === 1) {
        this.admin = true;
      }
    },
  },
};
</script>

<style scoped>
#table {
  /* height: 900px; */
  margin-top: 50px;
}
#main {
  margin-top: 10px;
}
#navbar {
  width: 100%;
}
#btn {
  margin-top: 10px;
  margin-bottom: 240px;
}
#form {
  margin-top: 50px;
  width: 90%;
}
.radio_form {
  display: flex;
  justify-content: center;
  align-items: center;
}#form_users {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>