<template class="sidebar-mini layout-fixed sidebar-open">
  <div class="wrapper">
    <!-- <Preloader /> -->
    <Navbar />
    <section class="content">
      <div class="container-fluid">
        <Sidebar />
        <div class="content-wrapper" >
          <!-- Content Header (Page header) -->
          <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-6">
                  <h1 class="m-0">Dashboard</h1>
                </div>
                <!-- /.col -->
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item ">Leads</li>
                    <li class="breadcrumb-item active"><a href="#">Dashboard</a></li>
                  </ol>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
            <Dashboard />
            <Table></Table>
          </div>
          <!-- /.content-header -->
          <!-- <Dashboard /> -->
        </div>
      </div>
    </section>
    <TheFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/layout/Sidebar.vue";
import Navbar from "@/components/layout/Navbar.vue";
import TheFooter from "@/components/layout/Footer.vue";
// import Preloader from "@/components/layout/PreloaderAPI.vue";
import Table from "@/components/leads/Table.vue";
import axios from "axios";
import Dashboard from "@/components/layout/Dashboard.vue";

// import Dashboard from "@/components/layout/Dashboard.vue";
// import Dashboard from "../../components/layout/Dashboard.vue";

export default {
  name: "HomeView",
  components: {
    Sidebar,
    Navbar,
    TheFooter,
    // Preloader,
    Table,
    Dashboard
},
  data() {
    return {
      leads: [],
      return: false
    };
  },
  methods: {
    List: function () {
       axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
        axios.get(this.$store.state.base_url+'leads/id/1').then(response => {
           console.log(response);
       })
    }
  },
  mounted() {
  //  this.List();
  }
};
</script>
<style scoped>
.wrapper {
  /* z-index: 1; */
}
</style>