<template>
  <!-- Small boxes (Stat box) -->
  <div class="row" style="margin: 10px">
    <div class="col-lg-3 col-6">
      <!-- small box -->
      <TotalVendas />
    </div>
    <!-- ./col -->
    <div class="col-lg-3 col-6">
      <!-- small box -->
      <TotalVendido />
    </div>
    <div class="col-lg-3 col-6">
      <!-- small box -->
      <SaldoPendente />
    </div>
    <div class="col-lg-3 col-6">
      <!-- small box -->
      <Saldo />
    </div>
  
  </div>
</template>

<script>
import TotalVendas from "../dashboard/TotalVendas.vue";
// import Calendar from '../dashboard/Calendar.vue';
import SaldoPendente from "../dashboard/SaldoPendente.vue";
import Saldo from "../dashboard/Saldo.vue";
import TotalVendido from "../dashboard/Total.vue";

export default {
  name: "TheDashboard",
  components: {
    TotalVendas,
    // Calendar,
    SaldoPendente,
    Saldo,
    TotalVendido,
  },
  created() {
    this.$forceUpdate();
  },
};
</script>

<style>
#calendar {
  max-width: 600px;
}
</style>