<template>
  <div id="footer">
      <footer class="main-footer">
    <strong>Copyright &copy; 2022 <a href="https://www.ecommloja.com.br/">E-commerce NET</a></strong>
    <div class="float-right d-none d-sm-inline-block">
      <span></span>
    </div>
  </footer>
  </div>
</template>

<script>
export default {
name: 'TheFooter',
}
</script>

<style scoped>
#footer{
  /* position: fixed; */
  bottom: 0;
  width: 100%;

}
</style>