import '@babel/polyfill'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'admin-lte'
import 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/admin-lte/dist/css/adminlte.css'
import '../node_modules/admin-lte/dist/js/adminlte.js'
import '../node_modules/admin-lte/plugins/jquery/jquery.min.js'
import '../node_modules/admin-lte/build/js/AdminLTE.js'
import '../node_modules/admin-lte/plugins/chart.js/Chart.css'
import '../node_modules/admin-lte/plugins/chart.js/Chart.js'
import '../node_modules/admin-lte/plugins/chart.js/Chart.bundle.js'
import '../node_modules/admin-lte/plugins/jsgrid/jsgrid.css'
import 'datatables.net'
import 'popper.js'
import 'sparklines'
import '../node_modules/font-awesome/css/font-awesome.css'
import '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-free'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'fullcalendar/main.js'
import 'mutationobserver-shim'
import money from 'v-money'
import VueMask from 'v-mask';
Vue.use(money, {precision: 4})
Vue.use(VueMask);

// Vue.use(icons);

Vue.config.productionTip = true

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
