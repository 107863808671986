<template>
  <section class="content">
    <form @submit.prevent="" class="row g-3 needs-validation mt-3">
      <div class="container-fluid">
        <div class="card card-default">
          <div class="card-header">
            <h3 class="card-title">Dados do lead</h3>
            <div class="card-tools">
              <button
                type="submit"
                class="btn btn-primary btn-sm"
                @click="create()"
              >
                Salvar
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="nome">Nome da loja</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="nome"
                    required
                    placeholder="Digite o nome"
                    v-model="nome"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">E-mail</label>
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    id="email"
                    placeholder="Digite o e-mail"
                    v-model="email"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="responsavel">Nome do responsável</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="responsavel"
                    placeholder="Digite o nome do responsável"
                    v-model="responsavel"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="segmento">Segmento</label>
                  <select
                    class="form-select form-control-sm"
                    id="segmento"
                    required
                    v-model="segmento"
                  >
                    <option value="">Selecione</option>
                    <option value="Acessórios para tecnologia">
                      Acessórios para tecnologia
                    </option>
                    <option value="Adulto">Adulto</option>
                    <option value="Alimentícios">Alimentícios</option>
                    <option value="Antiguidades e coleções">
                      Antiguidades e coleções
                    </option>
                    <option value="Artigos religiosos">
                      Artigos religiosos
                    </option>
                    <option value="Autos e peças">Autos e peças</option>
                    <option value="Bebês">Bebês</option>
                    <option value="Beleza e cuidados especiais">
                      Beleza e cuidados especiais
                    </option>
                    <option value="Brinquedas e hobbies">
                      Brinquedas e hobbies
                    </option>
                    <option value="Cursos">Cursos</option>
                    <option value="Decoração">Decoração</option>
                    <option value="Esporte fitness">Esporte fitness</option>
                    <option value="Eletrodomésticos">Eletrodomésticos</option>
                    <option value="Esporte e lazer">Esporte e lazer</option>
                    <option value="Eletrônicos e celulares">
                      Eletrônicos e celulares
                    </option>
                    <option value="Farmácia">Farmácia</option>
                    <option value="Festas e lembrancinhas">
                      Festas e lembrancinhas
                    </option>
                    <option value="Ferramentas e equipamentos">
                      Ferramentas e equipamentos
                    </option>
                    <option value="Ingressos">Ingressos</option>
                    <option value="Imóveis">Imóveis</option>
                    <option value="Instrumentos musicais">
                      Instrumentos musicais
                    </option>
                    <option value="Jardim">Jardim</option>
                    <option value="Jóias e relógios">Jóias e relógios</option>
                    <option value="Livros, revistas e commics">
                      Livros, revistas e commics
                    </option>
                    <option value="Máquinas e equipamentos agrícolas">
                      Máquinas e equipamentos agrícolas
                    </option>
                    <option value="Material construção">
                      Material construção
                    </option>
                    <option value="Moda">Moda</option>
                    <option value="Papelaria">Papelaria</option>
                    <option value="Petshop">Petshop</option>
                    <option value="Produtos e equipamentos de limpeza">
                      Produtos e equipamentos de limpeza
                    </option>
                    <option value="Serviços">Serviços</option>
                    <option value="Téxtil">Téxtil</option>
                    <option value="Ótica">Ótica</option>
                    <option value="Saúde">Saúde</option>
                    <option value="Geral">Geral</option>
                    <option value="Outros">Outros</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="telefone">Telefone</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="telefone"
                    placeholder="Digite o número de telefone"
                    v-model="telefone"
                    v-mask="'(##) #####-####'"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="whatsapp">Whatsapp</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="whatsapp"
                    placeholder="Digite o número do whatsapp"
                    v-model="whatsapp"
                    v-mask="'(##) #####-####'"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="cep">CEP</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="cep"
                    placeholder="Digite o CEP"
                    v-model="cep"
                    required
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="rua">Rua</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="rua"
                    placeholder="Digite a Rua"
                    v-model="rua"
                    required
                  />
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="numero">Nº</label>
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    id="numero"
                    placeholder="Digite o número"
                    v-model="numero"
                    required
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="complememnto">Complemento</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="complememnto"
                    placeholder="Digite o complemento"
                    v-model="complemento"
                    required
                  />
                </div>
              </div>
             
              <div class="col-md-2">
                <div class="form-group">
                  <label for="bairro">Bairro</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="bairro"
                    placeholder="Digite o Bairro"
                    v-model="bairro"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="cidade">Cidade</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="cidade"
                    placeholder="Digite a cidade"
                    v-model="cidade"
                    required
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="uf">UF</label>
                  <select
                    id="uf"
                    class="form-select form-control-sm"
                    v-model="uf"
                  >
                    <option value="">Selecione</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espirito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="status">Status</label>
                  <select
                    class="form-select form-control-sm"
                    id="status"
                    v-model="selectvalue"
                    required
                  >
                    <option active value="0">Novo Lead</option>
                    <option value="1">Primeiro Contato</option>
                    <option value="2">Segundo Contato</option>
                    <option value="3">Terceiro Contato</option>
                    <option value="4">Reunião Agendada</option>
                    <option value="5">Aguardando Proposta</option>
                    <option value="6">Proposta Enviada</option>
                    <option value="7">Gerar Contrato</option>
                    <option value="8">Venda Realizada</option>
                    <option value="9">Interesse Futuro</option>
                    <option value="10">Não Responde</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "LeadCreate",
  components: {},
  data() {
    return {
      email: "",
      nome: "",
      responsavel: "",
      numero: "",
      rua: "",
      bairro: "",
      cidade: "",
      uf: "",
      cep: "",
      telefone: "",
      segmento: "",
      whatsapp: "",
      complemento: "",
      id: "",
      id_led: "",
      select: "",
      selectvalue: "",
      response: "",
      state: "",
      comentario: "",
      user: "",
    };
  },

  methods: {
    return() {
      if (this.response.status == "success" && this.response.status != "") {
        this.state = true;
      } else {
        this.state = false;
      }
    },
    create() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}leads`, {
          email: this.email,
          nome_empresa: this.nome,
          nome_responsavel: this.responsavel,
          segmento: this.segmento,
          rua: this.rua,
          numero: this.numero,
          whatsapp: this.whatsapp,
          complemento: this.complemento,
          bairro: this.bairro,
          cidade: this.cidade,
          estado: this.uf,
          cep: this.cep,
          telefone: this.telefone,
          status: 1,
          tipo_lead: 1,
          status_lead: this.selectvalue,
          excluido: 0,
          id_parceiro: this.user.id,
          estado_lead: 1,
          observacao: this.comentario,
        })
        .then((response) => {
          console.log(response);
          this.state = true;
          this.$router.push("/leads");
        });
      this.return();
    },
    voltar() {
      this.$router.push("/leads");
    },
  },
  watch: {
    cep() {
      if (this.cep.length == 8) {
        axios
          .get(`https://viacep.com.br/ws/${this.cep}/json/`)
          .then((response) => {
            this.rua = response.data.logradouro;
            this.bairro = response.data.bairro;
            this.cidade = response.data.localidade;
            this.uf = response.data.uf;
          });
      }
    },
  },
  created() {
    this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
  },
};
</script>

<style scoped>
#main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
#btn {
  margin-top: 10px;
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}
#select {
  /* border: solid black 1px; */
  /* margin-left: 10px; */
  margin-top: 30px;
}
.contentform {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  /* border: solid black 1px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 50px;
}
.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 10px;
}
@media screen and (max-width: 768px) {
  .contentform {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>