<template>
  <div>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"
            ><i class="fas fa-bars"></i
          ></a>
        </li>
        <!-- <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/">
          <a class="nav-link">Home</a>
        </router-link>
      </li> -->
        <!-- <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link">Contact</a>
      </li> -->
      </ul>
      <ul class="navbar-nav ml-auto">
        

  

        
        <li class="nav-item">
          <a class="nav-link" data-widget="fullscreen" href="#" role="button">
            <i class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
      
      </ul>
      <!-- Right navbar links -->
    </nav>
    <!-- /.navbar -->
  </div>
</template>

<script>
export default {
  name: "TheNavbar",
};
</script>

<style>
</style>