<template  style="height: auto">
  <div class="wrapper" >
    <Preloader />
    <Navbar />
    <Sidebar />
    <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Geração de contrato</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="#">Leads</a>
                  </li>
                  <li class="breadcrumb-item active">Geração de contrato</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <Contrato />
      </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Sidebar from "@/components/layout/Sidebar.vue";
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
import Contrato from "@/components/leads/Contrato.vue";
import Preloader from "@/components/layout/Preloader.vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    Sidebar,
    Navbar,
    Footer,
    Contrato,
    Preloader
},
  data() {
    return {
      leads: [],
      return: false
    };
  },
  methods: {
    List: function () {
       axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
        axios.get(this.$store.state.base_url+'leads/id/1').then(response => {
           console.log(response);
       })
    }
  },
  mounted() {
  //  this.List();
  }
};
</script>
<style scoped>
  .wrapper {
    margin-bottom: 50px;
  }
</style>