<template>
  <div>
    <div v-if="message" class="alert alert-success form">{{ message }}</div>
    <form class="form">
      <div class="col-md-12">
        <label for="inputZip" class="form-label">nome</label>
        <input type="text" class="form-control" id="nome" v-model="nome" />
      </div>
      <div class="col-md-12">
        <label for="inputEmail4" class="form-label">Email</label>
        <input
          type="email"
          class="form-control"
          id="inputEmail4"
          v-model="email"
          disabled
        />
      </div>
      <div class="col-md-12">
        <label for="inputPassword4" class="form-label">Password</label>
        <input
          type="password"
          class="form-control"
          id="inputPassword4"
          v-model="senha"
        />
      </div>
      <div class="col-md-12">
        <label class="form-label">Ativo</label>
        <input type="checkbox" name="" id="checkbox" v-model="status" />
      </div>
      <div id="btn">
        <!-- <button
          type="button"
          id="button1"
          @click="voltar"
          class="btn btn-danger"
        >
          Voltar
        </button> -->
        <button
          type="button"
          id="button2"
          @click="update()"
          class="btn btn-success"
        >
          Cadastrar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "FormADM",
  data() {
    return {
      email: "",
      senha: "",
      nome: "",
      message: "",
      error: false,
      success: false,
      tipo: 1,
      parceiro_id: "",
      user: "",
      status: "",

      // lista: ''
    };
  },
  methods: {
    List: function () {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .get(
          `${this.$store.state.base_url}parceiro/${this.$router.history.current.params.id}`
        )
        .then((response) => {
          console.log(response);
          this.email = response.data.email;
          this.senha = response.data.senha;
          this.nome = response.data.name;
          this.status = parseInt(response.data.status);
          // this.tipo = parseInt(response.data.tipo);
        });
    },
    check: function () {
      if (this.user.id == this.user.parceiro_id) {
        this.parceiro_id = this.user.parceiro_id;
      } else {
        this.parceiro_id = this.user.id;
      }
    },
    update: function () {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .put(
          this.$store.state.base_url +
            "parceiros/" +
            this.$router.history.current.params.id,
          {
            password: this.senha,
            name: this.nome,
            // tipo: this.user.tipo,
            status: this.status,
          }
        )
        .then((response) => {
          // console.log(response);
          this.message = response.data.message;
          this.statusMsg = response.data.status;
        });
    },
    voltar() {
      this.$router.push("/usuarios");
    },
  },
  created() {
    this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
    this.List();
    this.check();
  },
};
</script>

<style>
#btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
#button1 {
  margin-right: 10px;
  margin-top: 20px;
}
#button2 {
  margin-left: 10px;
  margin-top: 20px;
}
#form {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px; */
}
.li {
  list-style: none;
}
.form {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 30px;
}
#checkbox {
  margin: 0px 0px 0px 4px;
}
</style>