<template>
  <!-- contrato content -->
  <section class="content mt-2">
    <form @submit.prevent="gerar()" class="row g-3 needs-validation">
      <div class="container-fluid">
        <div class="card card-default">
          <!-- <div class="card-header">
            <h3 class="card-title">Geração de contrato</h3>
            <div class="card-tools">
            <button type="submit" class="btn btn-primary">
              Gerar contrato
            </button>
          </div>
          </div> -->

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="nome">Nome</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="nome"
                    placeholder="Digite o nome"
                    required
                    v-model="nome"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">E-mail</label>
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    id="email"
                    required
                    placeholder="Digite o e-mail"
                    v-model="email"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="telefone">Telefone</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="telefone"
                    placeholder="Digite o número de telefone"
                    required
                    v-model="telefone"
                    v-mask="'(##) #####-####'"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="whatsapp">Whatsapp</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="whatsapp"
                    v-mask="'(##) #####-####'"
                    placeholder="Digite o número do whatsapp"
                    v-model="whatsapp"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="cnpj">CNPJ</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="cnpj"
                    required
                    placeholder="Digite o CNPJ"
                    v-model="cnpj"
                    v-mask="'##.###.###/####-##'"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="cep">CEP</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="cep"
                    required
                    placeholder="Digite o CEP"
                    v-model="cep"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="rua">Rua</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="rua"
                    required
                    placeholder="Digite a rua"
                    v-model="rua"
                  />
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="numero">Nº</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="numero"
                    placeholder="Nº"
                    required
                    v-model="numero"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="bairro">Bairro</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="bairro"
                    placeholder="Digite o número"
                    required
                    v-model="bairro"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="cidade">Cidade</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="cidade"
                    placeholder="Digite a cidade"
                    required
                    v-model="cidade"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="uf">UF</label>
                  <select
                    class="form-select form-control-sm"
                    id="uf"
                    required
                    v-model="uf"
                  >
                    <option value="">Selecione</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espirito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                  <!-- <input
                    type="text"
                    class="form-control form-control-sm"
                    id="uf"
                    required
                    placeholder="UF"
                    v-model="uf"
                  /> -->
                </div>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="valor_contrato">Valor do contrato</label>
                  <money v-model="valor_contrato" placeholder="Digite o valor do contrato"
                   class="form-control form-control-sm"  id="valor_contrato" v-bind="money"></money> {{price}}
                  <!-- <input
                    type="text"
                    class="form-control form-control-sm"
                    id="valor_contrato"
                    placeholder="Digite o valor do contrato"
                    required
                    v-model="valor_contrato"
                   
                    
                  /> -->
                </div>
              </div>
              <div class="col-md-6" v-if="entrada != 0">
                <div class="form-group">
                  <label for="qtd">Qtd. e valor das parcelas</label>
                  <select
                    class="form-control form-control-sm"
                    id="qtd"
                    v-model="parcelas"
                  >
                    <option value="1" active>
                      1x de R$ {{ valor_contrato - entrada }}
                    </option>
                    <option value="2">
                      2x de R$ {{ (parcela / 2).toFixed([2]) }}
                    </option>
                    <option value="3">
                      3x de R$ {{ (parcela / 3).toFixed([2]) }}
                    </option>
                    <option value="4">
                      4x de R$ {{ (parcela / 4).toFixed([2]) }}
                    </option>
                    <option value="5">
                      5x de R$ {{ (parcela / 5).toFixed([2]) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6" v-else>
                <div class="form-group">
                  <label for="qtd">Qtd. e valor das parcelas</label>
                  <select
                    id="qtd"
                    class="form-control form-control-sm"
                    required
                  >
                    <option value="1" active>
                      1x de R$ {{ valor_contrato }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="entrada">Valor da entrada</label>
                  <money v-model="entrada" v-bind="money"
                  class="form-control form-control-sm"
                    id="entrada"
                    placeholder="Digite o valor da entrada"
                    required
                  ></money> {{price}}
                  <!-- <input
                    type="text"
                    class="form-control form-control-sm"
                    id="entrada"
                    placeholder="Digite o valor da entrada"
                    required
                    v-model="entrada"
             
                  /> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="pagamento">Método de pagamento</label>
                  <select
                    class="form-control form-control-sm"
                    id="pagamento"
                    v-model="modo_pagamento"
                    required
                  >
                    <option>Selecione um método de pagamento</option>
                    <option value="1">Boleto Bancário</option>
                    <option value="2">Cartão</option>
                    <option value="3">Transferência Bancária</option>
                  </select>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-5">
                <div class="form-group">
                  <label for="dominio">Domínio</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text form-control-sm">www.</span>
                    </div>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="dominio"
                      placeholder="Digite o domínio"
                      v-model="url"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="categoria">Selecione</label>
                  <select
                    class="form-control form-control-sm"
                    id="categoria"
                    v-model="select"
                    required
                  >
                    <option value="" hidden="" selected="">
                      Selecione uma categoria...
                    </option>
                    <option value=".adm.br">.adm.br</option>
                    <option value=".adv.br">.adv.br</option>
                    <option value=".arq.br">.arq.br</option>
                    <option value=".art.br">.art.br</option>
                    <option value=".ato.br">.ato.br</option>
                    <option value=".bio.br">.bio.br</option>
                    <option value=".com">.com</option>
                    <option value=".com.br" selected="">.com.br</option>
                    <option value=".esp.br">.esp.br</option>
                    <option value=".fot.br">.fot.br</option>
                    <option value=".ind.br">.ind.br</option>
                    <option value=".inf.br">.inf.br</option>
                    <option value=".info">.info</option>
                    <option value=".net">.net</option>
                    <option value=".net.br">.net.br</option>
                    <option value=".ntr.br">.ntr.br</option>
                    <option value=".odo.br">.odo.br</option>
                    <option value=".org">.org</option>
                    <option value=".srv.br">.srv.br</option>
                    <option value=".tur.br">.tur.br</option>
                    <option value=".vet.br">.vet.br</option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label for="plano">Plano</label>
                  <select
                    class="form-control form-control-sm"
                    id="plano"
                    v-model="plano_id"
                    required
                  >
                    <option active>Selecione um plano</option>
                    <option
                      v-for="(plano, index) in planos"
                      :key="index"
                      :value="plano.id"
                    >
                      {{ plano.nome }} | R${{ plano.valor }},00
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-end m-2">
            <button type="submit" class="btn btn-primary btn-sm">
              Gerar contrato
            </button>
          </div>
        </div>
      </div>
    </form>
  </section>
  <!-- contrato end -->
</template>


<script>
import axios from "axios";


export default {
  name: "ContratoView",
  data() {
    return {
      plano_id: "",
      id_parceiro: "",
      id_indicacao: "",
      tipo: "",
      email: "",
      email_pagamento: "",
      nome: "",
      nome_loja: "",
      cnpj: "",
      valor: "",
      status_loja: "",
      status_pagamento: "",
      data_criacao: "",
      cpf: "",
      telefone: "",
      url: "",
      dominio_completo: "",
      observacao: "",
      situacao: "",
      banco_dados: "",
      arquivo_configuracao: "",
      termos_uso: "",
      data_cadastro: "",
      data_inicio: "",
      data_final: "",
      data_pagamento: "",
      data_bloqueio: "",
      dia_vencimento: "",
      cobrar: "",
      ultima_atualizacao: "",
      cep: "",
      cidade: "",
      uf: "",
      bairro: "",
      rua: "",
      numero: "",
      valor_contrato: 1250,
      entrada: "",
      parcelas: 1,
      modo_pagamento: "",
      modo_pagamento_entrada: "",
      nome_assinante: "",
      cpf_assinante: "",
      ip_assinante: "",
      data_assinatura: "",
      boleto_setup: "",
      status: "",
      excluido: "",
      id_matriz: "",
      servidor: "",
      planos: [],
      select: "",
      idUser: "",
      msg: "",
      parce: 0,
      whatsapp: "",
      money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
          masked: false
        }
     
    };
  },

  
  watch: {
    entrada: function () {
      if (this.entrada > 0 && this.entrada <= this.valor_contrato) {
        this.parcela = this.valor_contrato - this.entrada;
      } else {
        this.entrada = 0;
      }
    },
    cep() {
  
        if (this.cep.length == 8) {
          axios
            .get(`https://viacep.com.br/ws/${this.cep}/json/`)
            .then((response) => {
              this.rua = response.data.logradouro;
              this.bairro = response.data.bairro;
              this.cidade = response.data.localidade;
              this.uf = response.data.uf;
            });
        }
      
    },
    // valor_contrato(value) {
    //   console.log(value)
    // }
  },

  methods: {
    List() {
      axios
        .get(
          `${this.$store.state.base_url}lead/${this.$router.history.current.params.id}`
        )
        .then((response) => {
          // console.log(response.data.lead);
          this.nome = response.data.lead.nome_responsavel;
          this.email = response.data.lead.email;
          this.email_pagamento = response.data.lead.email_pagamento;
          this.telefone = response.data.lead.telefone;
          this.cep = response.data.lead.cep;
          this.cidade = response.data.lead.cidade;
          this.uf = response.data.lead.estado;
          this.bairro = response.data.lead.bairro;
          this.rua = response.data.lead.rua;
          this.numero = response.data.lead.numero;
          this.nome_loja = response.data.lead.nome_empresa;
          // event.preventDefault(response);
        });
      axios.get(`${this.$store.state.base_url}planos`).then((response) => {
        this.planos = response.data;
        this.valor = this.planos;
        // console.log(this.$refs.myDiv.value);
        // this.valor = response.data;
        // console.log(this.valor);
      });
    },
    exit() {
      this.$router.push("/leads");
    },
    gerar() {
      if (this.cnpj == "") {
        this.msg = "Preencha o CNPJ";
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}contratos`, {
          plano_id: this.plano_id,
          id_parceiro: this.idUser.id,
          id_indicacao: this.$router.history.current.params.id,
          tipo: 1,
          email: this.email,
          email_pagamento: this.email,
          nome: this.nome,
          nome_loja: this.nome_loja,
          cnpj: this.cnpj,
          valor: this.valor,
          status_loja: 1,
          status_pagamento: 1,
          // data_criacao: "2022-01-01 00:00:00",
          cpf: "",
          telefone: this.telefone,
          url: "www." + this.url + ".ecommloja.com.br",
          dominio_completo: "www." + this.url + this.select,
          observacoes: " ",
          situacao: 3,
          banco_dados: 0,
          arquivo_configuracao: 0,
          termos_uso: 0,
          // created_at: '2022-01-01 00:00:00',
          // data_inicio: "2022-01-01 00:00:00",
          // data_final: "2022-01-01 00:00:00",
          // data_pagamento: "2022-01-01 00:00:00",
          // data_bloqueio: "2022-01-01 00:00:00",
          // data_cadastro: "2022-01-01 00:00:00",
          dia_vencimento: 20,
          cobrar: 1,
          // updated_at: '2022-01-01 00:00:00',
          cep: this.cep,
          cidade: this.cidade,
          uf: this.uf,
          bairro: this.bairro,
          rua: this.rua,
          numero: this.numero,
          valor_contrato: this.valor_contrato,
          entrada: this.entrada,
          parcelas: this.parcelas,
          modo_pagamento: this.modo_pagamento,
          modo_pagamento_entrada: this.modo_pagamento,
          nome_assinante: " ",
          cpf_assinante: " ",
          ip_assinante: " ",
          // data_assinatura: "2022-01-01 00:00:00",
          boleto_setup: 0,
          status: 1,
          excluido: 0,
          id_matriz: 0,
          servidor: 2,
        })
        .then((response) => {
          console.log(response);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
          axios
            .put(
              `${this.$store.state.base_url}leads/${this.$router.history.current.params.id}`,
              {
                status_lead: 11,
              }
            )
            .then((response) => {
              console.log(response);
            });
          if (response.status == 201) {
            this.$router.push("/leads");
            console.log("teste");
          }
          // this.$router.push("/leads");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getvalor(valor) {
      console.log(valor);
      // console.log('teste')
    },
  },
  created() {
    this.idUser = JSON.parse(window.atob(sessionStorage.getItem("session")));
    // console.log(this.idUser)
    this.List();
  },
};
</script>
  

<style>
</style>