<template>
  <div>
    <Preloader />
    <div class="hold-transition login-page">
      <div class="login-box index-login">
        <div class="login-logo">
          <a href="/login">eCommerce<b>-net</b></a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
          <div class="card-body login-card-body">
            <form id="form">
              <div class="col-md-12">
                <label for="inputEmail4" class="form-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="inputEmail4"
                  v-model="email"
                />
              </div>
              <div class="col-md-12">
                <label for="inputEmail4" class="form-label">nome</label>
                <input
                  type="text"
                  class="form-control"
                  id="nome"
                  v-model="nome"
                />
              </div>
              <div class="col-md-12">
                <label for="inputPassword4" class="form-label">Senha</label>
                <input
                  type="password"
                  class="form-control"
                  id="inputPassword4"
                  placeholder="*********"
                  v-model="senha"
                />
              </div>
              <div class="col-md-12">
                <label for="inputZip" class="form-label">Cep</label>
                <input
                  type="text"
                  v-mask="'#####-###'"
                  class="form-control"
                  id="inputZip"
                  v-model="cep"
                />
              </div>
              <div class="col-12">
                <label for="inputAddress" class="form-label">Rua</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress"
                  placeholder="EX Rua do Flamengo"
                  v-model="rua"
                />
              </div>
              <div class="col-12">
                <label for="inputAddress2" class="form-label">Bairro</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress2"
                  placeholder=""
                  v-model="bairro"
                />
              </div>
              <div class="col-md-12">
                <label for="inputCity" class="form-label">Cidade</label>
                <input
                  v-model="cidade"
                  type="text"
                  class="form-control"
                  id="inputCity"
                />
              </div>
              <div class="col-md-12">
                <label for="inputState" class="form-label">UF</label>
                <input class="form-control" type="text" v-model="uf" />
              </div>
            </form>
            <div class="class-btn">
              <button
                type="button"
                @click="create()"
                class="btn btn-primary btn-block"
              >
                Cadastrar
              </button>
            </div>
          </div>
          <!-- /.login-card-body -->
        </div>
      </div>
      <!-- /.login-box -->
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/layout/Preloader.vue";
import axios from "axios";
export default {
  name: "TheLogin",
  data() {
    return {
      cidade: "",
      email: "",
      senha: "",
      nome: "",
      message: "",
      error: false,
      success: false,
      // tipo: 1,
      parceiro_id: "",
      user: "",
      status: "",
      rua: "",
      bairro: "",
      uf: "",
      cep: "",
      bonus: "",
    };
  },
  components: { Preloader },
  methods: {
    create: function () {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(this.$store.state.create , {
          email: this.email,
          password: this.senha,
          name: this.nome,
          cidade: this.cidade,
          status: this.status,
          parceiro_id: 1,
          tipo: 3,
          porcentagem_bonus: 20,
          rua: this.rua,
          bairro: this.bairro,
          estado: this.uf,
          cep: this.cep,
        })
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          this.statusMsg = response.data.status;
          this.$router.push("/login");
        });
    },
  },
};
</script>

<style scoped>
.class-btn {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}
#form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
}
.index-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  margin-top: -20%;
}
.card {
  width: 800px;
}
@media screen and (max-width: 500px) {
  .card {
    width: 100%;
  }
  #form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    width: 100%;
  }
  .index-login {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100%;
  }
  .login-page {
    height: 100%;
  }
}
</style>