import { render, staticRenderFns } from "./Leads.vue?vue&type=template&id=5924436c&scoped=true&class=sidebar-mini%20layout-fixed%20sidebar-open&"
import script from "./Leads.vue?vue&type=script&lang=js&"
export * from "./Leads.vue?vue&type=script&lang=js&"
import style0 from "./Leads.vue?vue&type=style&index=0&id=5924436c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5924436c",
  null
  
)

export default component.exports