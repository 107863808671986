<template>
  <router-view />
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      // aut: '',
      time: "",
      response: "",
    };
  },
  methods: {
    auth() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}me`)
        .then((response) => {
          sessionStorage.setItem("user", response.data.id);
          sessionStorage.setItem("session", window.btoa(JSON.stringify(response.data)));
        })
        .catch((error) => {
          console.log(error);
          this.$router.push("/login");
        });
    },
  },
  created() {
    // this.auth();
    // this.auth();
    console.log(window.btoa("admin:admin"));
    console.log(window.atob("YWRtaW46YWRtaW4="));

    this.auth();
  },
  beforeMount() {
    
  }


};
</script>
<style>
</style>
