import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/home/HomeView.vue'
import Login from '../views/login/Login.vue'
import Leads from '../views/leads/Leads.vue'
import LeadUpdate from '../views/leads/LeadUpdate.vue'
import contrato from '../views/leads/Contrato.vue'
import LeadCreate from '../views/leads/LeadCreate.vue'
import Usuarios from '../views/usuarios/Usuarios.vue'
import UsuariosUpdate from '../views/usuarios/UsuariosUpdate.vue'
import UsuariosCreate from '../views/usuarios/UsuariosCreate.vue'
import Cadastrar from '../views/login/Cadastrar.vue'
import axios from 'axios'
import store from '../store/index.js'


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/leads',
    name: 'leads',
    component: Leads,
    meta: {
      login: true
    },
  },
  {
    path: '/leads/update/:id',
    name: 'LeadUpdate',
    component: LeadUpdate,
    meta: {
      login: true
    },
  },
  {
    path: '/contratos/:id',
    name: 'ContratoView',
    component: contrato,
    meta: {
      login: true
    },
  },
  {
    path: '/contratos/:id',
    name: 'ContratoView',
    component: contrato,
    meta: {
      login: true
    },
  },
  {
    path: '/leads/create',
    name: 'LeadCreate',
    component: LeadCreate,
    meta: {
      login: true
    },
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: Usuarios,
    meta: {
      login: true
    },
  },
  {
    path: '/usuarios/update/:id',
    name: 'UsuariosUpdate',
    component: UsuariosUpdate,
    meta: {
      login: true
    },
  },
  {
    path: '/usuarios/create',
    name: 'UsuariosCreate',
    component: UsuariosCreate,
    meta: {
      login: true
    },
  },
  {
    path: '/new',
    name: 'Cadastrar',
    component: Cadastrar
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  // console.log(store.state.base_url)
  // console.log(to.matched)
  // to.matched.some(record => { console.log(record.meta.login) })
  // console.log(to.matched.some(record => { record.meta.login }))
  if (to.matched.some(record => record.meta.login)) {
    console.log('requiresAuth')
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + sessionStorage.getItem("token");
    axios.post(store.state.base_url + "me")
      .then(response => {
        // console.log(response)
        sessionStorage.setItem(
          "session",
          window.btoa(JSON.stringify(response.data))
        );
        next();
      }).catch(error => {
        console.log(error);
        next('/login');
      });

  } else {
    next();
  }
})

export default router
