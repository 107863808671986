<template>
  <div id="main">
    <div v-if="message" class="alert alert-success form">{{ message }}</div>
    <form id="form"  v-on:keyup.enter="create()" >
      <div class="col-md-12">
        <label for="inputEmail4" class="form-label">Email</label>
        <input
          type="email"
          class="form-control"
          id="inputEmail4"
          v-model="email"
        />
      </div>
      <div class="col-md-12">
        <label for="inputEmail4" class="form-label">nome</label>
        <input type="text" class="form-control" id="nome" v-model="nome" />
      </div>
      <div class="col-md-12">
        <label for="inputPassword4" class="form-label">Senha</label>
        <input
          type="password"
          class="form-control"
          id="inputPassword4"
          placeholder="*********"
          v-model="senha"
        />
      </div>
      <div class="col-12" v-if="user.tipo == 1 || user.tipo == 2">
        <label for="inputAddress" class="form-label">Bônus (%)</label>
        <input type="number" class="form-control" v-model="bonus" />
      </div>
      <div class="col-md-12">
        <label for="inputZip" class="form-label">Cep</label>
        <input
          type="text"
          class="form-control"
          id="inputZip"
          v-model="cep"
        />
      </div>
      <div class="col-12">
        <label for="inputAddress" class="form-label">Rua</label>
        <input
          type="text"
          class="form-control"
          id="inputAddress"
          placeholder="EX Rua do Flamengo"
          v-model="rua"
        />
      </div>
      <div class="col-12">
        <label for="inputAddress2" class="form-label">Bairro</label>
        <input
          type="text"
          class="form-control"
          id="inputAddress2"
          placeholder=""
          v-model="bairro"
        />
      </div>
      <div class="col-md-12">
        <label for="inputCity" class="form-label">Cidade</label>
        <input
          v-model="cidade"
          type="text"
          class="form-control"
          id="inputCity"
        />
      </div>
      <div class="col-md-12">
        <label for="inputState" class="form-label">UF</label>
        <input class="form-control" type="text" v-model="uf" />
      </div>
      <button
        type="button"
        id="button2"
        @click="create()"
        class="btn btn-success"
      >
        Cadastrar
      </button>
    </form>
    <!-- <div id="btn">
      <button
        type="button"
        id="button2"
        @click="create()"
        class="btn btn-success"
      >
        Cadastrar
      </button>
    </div> -->
  </div>
</template>


<script>
import axios from "axios";
export default {
  name: "FormParceiro",
  data() {
    return {
      cidade: "",
      email: "",
      senha: "",
      nome: "",
      message: "",
      error: false,
      success: false,
      // tipo: 1,
      parceiro_id: "",
      user: "",
      status: "",
      rua: "",
      bairro: "",
      uf: "",
      cep: "",
      bonus: "",

      // lista: ''
    };
  },
  watch: {
    cep() {
      if (this.cep.length == 8) {
        axios
          .get(`https://viacep.com.br/ws/${this.cep}/json/`)
          .then((response) => {
            this.rua = response.data.logradouro;
            this.bairro = response.data.bairro;
            this.cidade = response.data.localidade;
            this.uf = response.data.uf;
          });
      }
    },
  },
  methods: {
    List: function () {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .get(
          `${this.$store.state.base_url}parceiro/${this.$router.history.current.params.id}`
        )
        .then((response) => {
          console.log(response);
          this.email = response.data.email;
          // this.senha = response.data.password;
          this.nome = response.data.cep;
          this.rua = response.data.rua;
          this.bairro = response.data.bairro;
          this.cidade = response.data.cidade;
          this.uf = response.data.estado;
          this.nome = response.data.name;
          this.cep = response.data.cep;
          this.bonus = response.data.porcentagem_bonus;
          this.status = parseInt(response.data.status);
          // this.tipo = parseInt(response.data.tipo);
        });
    },
    check: function () {
      if (this.user.id == this.user.parceiro_id) {
        this.parceiro_id = this.user.parceiro_id;
      } else {
        this.parceiro_id = this.user.id;
      }
    },
    create: function () {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(this.$store.state.base_url + "parceiros", {
          email: this.email,
          password: this.senha,
          name: this.nome,
          cidade: this.cidade,
          status: this.status,
          parceiro_id: this.user.id,
          tipo: 3,
          porcentagem_bonus: this.bonus,
          rua: this.rua,
          bairro: this.bairro,
          estado: this.uf,
          cep: this.cep,
        })
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          this.statusMsg = response.data.status;
        });
    },
    voltar() {
      this.$router.push("/usuarios");
    },
  },
  created() {
    this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
    this.List();
    this.check();
  },
};
</script>

<style>
#btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
#button1 {
  margin-right: 10px;
  margin-top: 20px;
}
#button2 {
  margin-left: 10px;
  margin-top: 20px;
}
#main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.li {
  list-style: none;
}
#form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 30px;
}
#checkbox {
  margin: 0px 0px 0px 4px;
}
</style>