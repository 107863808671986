import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    base_url: "http://api.parceiros.ecommloja.com.br/api/v1/",
    url_login: "http://api.parceiros.ecommloja.com.br/api/login",
    create: "http://api.parceiros.ecommloja.com.br/api/parceiros",
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
