<template>
  <div>
    <!-- /.card-header -->
    <Preloader v-if="loading" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Leads</h3>
              <div class="card-tools">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#Modal"
                  id="btn_fast"
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  Cadastro Rápido
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-lightning-charge"
                    viewBox="0 0 16 16"
                  >
                    <path
                      class="thunder"
                      d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z"
                    />
                  </svg>
                </button>
                <button @click="create()" class="btn btn-primary btn-sm">
                  Cadastro
                </button>
              </div>
            </div>

            <div class="card-body table-responsive p-0" style="height: 400px">
              <table class="table table-head-fixed text-nowrap">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nome da loja</th>
                    <th>Nome do responsável</th>
                    <th>Telefone</th>
                    <th>Whatsapp</th>
                    <th>E-mail</th>
                    <th>Segmento</th>
                    <th>Status</th>
                    <th>Ação</th>
                  </tr>
                  <tr>
                    <th
                      class="
                        d-flex
                        justify-content-flex-start
                        align-items-flex-start
                      "
                    >
                      <button
                        @click="List()"
                        class="btn btn-outline-danger btn-sm"
                        title="Limpar Filtros"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-x"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </button>
                    </th>
                    <th>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="Nome da loja"
                        v-model="orderNomes"
                      />
                    </th>
                    <th>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="Nome do responsável"
                        v-model="orderResponsavel"
                      />
                    </th>
                    <th>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="Telefone"
                        v-model="orderTelefone"
                        v-mask="'(##) #####-####'"
                      />
                    </th>
                    <th>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="Whatsapp"
                        v-model="orderWhatsapp"
                        v-mask="'(##) #####-####'"
                      />
                    </th>
                    <th>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="E-mail"
                        v-model="orderEmail"
                      />
                    </th>
                    <th>
                      <select
                        class="form-select form-control-sm"
                        v-model="orderSegmentos"
                      >
                        <option value="">Selecione</option>
                        <option value="Acessórios para tecnologia">
                          Acessórios para tecnologia
                        </option>
                        <option value="Adulto">Adulto</option>
                        <option value="Alimentícios">Alimentícios</option>
                        <option value="Antiguidades e coleções">
                          Antiguidades e coleções
                        </option>
                        <option value="Artigos religiosos">
                          Artigos religiosos
                        </option>
                        <option value="Autos e peças">Autos e peças</option>
                        <option value="Bebês">Bebês</option>
                        <option value="Beleza e cuidados especiais">
                          Beleza e cuidados especiais
                        </option>
                        <option value="Brinquedas e hobbies">
                          Brinquedas e hobbies
                        </option>
                        <option value="Cursos">Cursos</option>
                        <option value="Decoração">Decoração</option>
                        <option value="Esporte fitness">Esporte fitness</option>
                        <option value="Eletrodomésticos">
                          Eletrodomésticos
                        </option>
                        <option value="Esporte e lazer">Esporte e lazer</option>
                        <option value="Eletrônicos e celulares">
                          Eletrônicos e celulares
                        </option>
                        <option value="Farmácia">Farmácia</option>
                        <option value="Festas e lembrancinhas">
                          Festas e lembrancinhas
                        </option>
                        <option value="Ferramentas e equipamentos">
                          Ferramentas e equipamentos
                        </option>
                        <option value="Ingressos">Ingressos</option>
                        <option value="Imóveis">Imóveis</option>
                        <option value="Instrumentos musicais">
                          Instrumentos musicais
                        </option>
                        <option value="Jardim">Jardim</option>
                        <option value="Jóias e relógios">
                          Jóias e relógios
                        </option>
                        <option value="Livros, revistas e commics">
                          Livros, revistas e commics
                        </option>
                        <option value="Máquinas e equipamentos agrícolas">
                          Máquinas e equipamentos agrícolas
                        </option>
                        <option value="Material construção">
                          Material construção
                        </option>
                        <option value="Moda">Moda</option>
                        <option value="Papelaria">Papelaria</option>
                        <option value="Petshop">Petshop</option>
                        <option value="Produtos e equipamentos de limpeza">
                          Produtos e equipamentos de limpeza
                        </option>
                        <option value="Serviços">Serviços</option>
                        <option value="Téxtil">Téxtil</option>
                        <option value="Ótica">Ótica</option>
                        <option value="Saúde">Saúde</option>
                        <option value="Geral">Geral</option>
                        <option value="Outros">Outros</option>
                      </select>
                    </th>
                    <th>
                      <select
                        class="form-select form-control-sm"
                        v-model="selectvalue"
                      >
                        <option class="b" active value="7">Status</option>
                        <option class="" value="0">Novo Lead</option>
                        <option class="" value="1">Primeiro Contato</option>
                        <option class="" value="2">Segundo Contato</option>
                        <option class="" value="3">Terceiro Contato</option>
                        <option class="" value="4">Reunião Agendada</option>
                        <option class="" value="5">Aguardando Proposta</option>
                        <option class="" value="6">Proposta Enviada</option>
                        <!-- <option class="btn btn-success" value="7">
                    Gerar Contrato
                  </option> -->
                        <option @click="VendaRealizada()" class="" value="8">
                          Venda Realizada
                        </option>
                        <option class="" value="9">Interesse Futuro</option>
                        <option class="" value="10">Não Responde</option>
                        <option class="" value="11">Contrato Gerado</option>
                      </select>
                    </th>
                    <th>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="orderAll()"
                      >
                        Pesquisar
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(lead, index) in leads" :key="index">
                    <td>{{ lead.id }}</td>
                    <td><button  @click="Editar(lead.id)" class="btn btn-link">{{ lead.nome_empresa }}</button></td>
                    <td>{{ lead.nome_responsavel }}</td>
                    <td>{{ lead.telefone }}</td>
                    <td>{{ lead.whatsapp }}</td>
                    <td>{{ lead.email }}</td>
                    <td>
                      <span v-if="lead.segmento">{{ lead.segmento }}</span>
                      <span v-else>Não informado</span>
                    </td>
                    <td>
                      <span
                        v-if="lead.status_lead == 0"
                        class="badge"
                        style="background-color: #6c757d"
                      >
                        Novo Lead
                      </span>
                      <span
                        v-if="lead.status_lead == 1"
                        class="badge"
                        style="background-color: #ffc107"
                      >
                        Primeiro Contato
                      </span>
                      <span
                        v-if="lead.status_lead == 2"
                        class="badge"
                        style="background-color: #ffc107"
                      >
                        Segundo Contato
                      </span>
                      <span
                        v-if="lead.status_lead == 3"
                        class="badge"
                        style="background-color: #ffc107"
                      >
                        Terceiro Contato
                      </span>
                      <span
                        v-if="lead.status_lead == 4"
                        class="badge"
                        style="background-color: #dc3545"
                      >
                        Reunião Agendada
                      </span>
                      <span
                        v-if="lead.status_lead == 5"
                        class="badge"
                        style="background-color: #007bff"
                      >
                        Aguardando Proposta
                      </span>
                      <span
                        v-if="lead.status_lead == 6"
                        class="badge"
                        style="background-color: #007bff"
                      >
                        Proposta Enviada
                      </span>
                      <span
                        v-if="lead.status_lead == 7"
                        class="badge"
                        style="background-color: #28a745"
                      >
                        Gerar Contrato
                      </span>
                      <span
                        v-if="lead.status_lead == 8"
                        class="badge"
                        style="background-color: #28a745"
                      >
                        Venda Realizada
                      </span>
                      <span
                        v-if="lead.status_lead == 9"
                        class="badge"
                        style="background-color: #6c757d"
                      >
                        Interesse Futuro
                      </span>
                      <span
                        v-if="lead.status_lead == 10"
                        class="badge"
                        style="background-color: #6c757d"
                      >
                        Não Responde
                      </span>
                      <span
                        v-if="lead.status_lead == 11"
                        class="badge"
                        style="background-color: #28a745"
                      >
                        Contrato Gerado
                      </span>
                      <span
                        v-if="lead.status_lead == 12"
                        class="badge"
                        style="background-color: #6c757d"
                      >
                        Finalizado
                      </span>
                    </td>
                    <td>
                      <button
                        @click="Editar(lead.id)"
                        type="button"
                        class="btn btn-link"
                      >
                        Editar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="Modal"
      tabindex="-1"
      aria-labelledby="eodalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ModalLabel">Cadastro Rápido de Lead</h5>
            <div @click="purge()">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent="" class="row g-3 needs-validation">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="nome_empresa">Nome da loja</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="nome_empresa"
                        :required="true"
                        placeholder="Digite o nome da loja"
                        v-model="nome"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="nome_responsavel">Nome do responsável</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="nome_responsavel"
                        placeholder="Digite o nome do responsável"
                        v-model="responsavel"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">E-mail</label>
                      <input
                        type="email"
                        class="form-control form-control-sm"
                        id="email"
                        placeholder="Digite o e-mail"
                        v-model="email"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="segmento">Segmento</label>
                      <select
                        class="form-control form-control-sm"
                        id="segmento"
                        v-model="segmento"
                      >
                        <option value="">Selecione</option>
                        <option value="Acessórios para tecnologia">
                          Acessórios para tecnologia
                        </option>
                        <option value="Adulto">Adulto</option>
                        <option value="Alimentícios">Alimentícios</option>
                        <option value="Antiguidades e coleções">
                          Antiguidades e coleções
                        </option>
                        <option value="Artigos religiosos">
                          Artigos religiosos
                        </option>
                        <option value="Autos e peças">Autos e peças</option>
                        <option value="Bebês">Bebês</option>
                        <option value="Beleza e cuidados especiais">
                          Beleza e cuidados especiais
                        </option>
                        <option value="Brinquedas e hobbies">
                          Brinquedas e hobbies
                        </option>
                        <option value="Cursos">Cursos</option>
                        <option value="Decoração">Decoração</option>
                        <option value="Esporte fitness">Esporte fitness</option>
                        <option value="Eletrodomésticos">
                          Eletrodomésticos
                        </option>
                        <option value="Esporte e lazer">Esporte e lazer</option>
                        <option value="Eletrônicos e celulares">
                          Eletrônicos e celulares
                        </option>
                        <option value="Farmácia">Farmácia</option>
                        <option value="Festas e lembrancinhas">
                          Festas e lembrancinhas
                        </option>
                        <option value="Ferramentas e equipamentos">
                          Ferramentas e equipamentos
                        </option>
                        <option value="Ingressos">Ingressos</option>
                        <option value="Imóveis">Imóveis</option>
                        <option value="Instrumentos musicais">
                          Instrumentos musicais
                        </option>
                        <option value="Jardim">Jardim</option>
                        <option value="Jóias e relógios">
                          Jóias e relógios
                        </option>
                        <option value="Livros, revistas e commics">
                          Livros, revistas e commics
                        </option>
                        <option value="Máquinas e equipamentos agrícolas">
                          Máquinas e equipamentos agrícolas
                        </option>
                        <option value="Material construção">
                          Material construção
                        </option>
                        <option value="Moda">Moda</option>
                        <option value="Papelaria">Papelaria</option>
                        <option value="Petshop">Petshop</option>
                        <option value="Produtos e equipamentos de limpeza">
                          Produtos e equipamentos de limpeza
                        </option>
                        <option value="Serviços">Serviços</option>
                        <option value="Téxtil">Téxtil</option>
                        <option value="Ótica">Ótica</option>
                        <option value="Saúde">Saúde</option>
                        <option value="Geral">Geral</option>
                        <option value="Outros">Outros</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="telefone">Telefone</label>
                      <input
                        type="text"
                        v-mask="'(##) #####-####'"
                        required="true"
                        id="telefone"
                        class="form-control form-control-sm"
                        placeholder="Digite o número de telefone"
                        v-model="telefone"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="whatsapp">Whatsapp</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="whatsapp"
                        v-mask="'(##) #####-####'"
                        placeholder="Digite o número do whatsapp"
                        v-model="whatsapp"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="createNewLead()"
                  id="fast-create"
                  type="submit"
                  class="btn btn-primary"
                >
                  Cadastrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Preloader from "../layout/PreloaderAPI.vue";

export default {
  name: "TableLeads",
  components: {
    Preloader,
  },
  data() {
    return {
      leads: [],
      user: "",
      loading: true,
      state: false,
      email: "",
      nome: "",
      responsavel: "",
      numero: "",
      rua: "",
      bairro: "",
      cidade: "",
      uf: "",
      cep: "",
      telefone: "",
      segmento: "",
      whatsapp: "",
      complemento: "",
      selectvalue: 7,
      select: "",
      response: "",
      stateComentario: "",
      comentario: "",
      id: "",
      comentarios: "",
      idLead: "",
      order: true,
      orderSegmentos: "",
      orderNomes: "",
      orderEmail: "",
      orderResponsavel: "",
      orderTelefone: "",
      orderWhatsapp: "",
    };
  },
  watch: {
    selectvalue: function (val) {
      if (val != 7) {
        this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + sessionStorage.getItem("token");
        axios
          .get(this.$store.state.base_url + "leads/id/" + this.user.id)
          .then((response) => {
            //  console.log(response);
            // this.loading = false;
            this.leads = response.data.filter((lead) => {
              return lead.status_lead == val;
            });
          });
      } else {
        this.List();
      }
    },
    orderSegmentos: function (value) {
      // console.log(value)
      // console.log('teste')
      if (value) {
        this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + sessionStorage.getItem("token");
        axios
          .get(this.$store.state.base_url + "leads/id/" + this.user.id)
          .then((response) => {
            //  console.log(response);
            // this.loading = false;
            this.leads = response.data.filter(
              ({ segmento }) => segmento == value
            );
            console.log(this.leads);
          });
      }
    },
  },
  methods: {
    List: function () {
      this.orderSegmentos = "",
      this.orderNomes = "",
      this.orderEmail = "",
      this.orderResponsavel = "",
      this.orderTelefone = "",
      this.orderWhatsapp = "",
      this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .get(this.$store.state.base_url + "leads/id/" + this.user.id)
        .then((response) => {
          // console.log(response);
          this.leads = response.data.reverse();
          this.loading = false;
        });
    },
    VendaRealizada: function () {
      this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .get(this.$store.state.base_url + "leads/id/" + this.user.id)
        .then((response) => {
          //  console.log(response);
          // this.loading = false;
          this.leads = response.data.filter((lead) => {
            return lead.status_lead == 8;
          });
        });
    },
    orderName: function () {
      this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .get(this.$store.state.base_url + "leads/id/" + this.user.id)
        .then((response) => {
          //  console.log(response);
          // this.loading = false;
          this.leads = response.data.sort((a, b) => {
            return b.status_lead - a.status_lead;
          });
        });
    },
    orderAll: function () {
      if (this.orderNomes) {
        this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + sessionStorage.getItem("token");
        axios
          .get(this.$store.state.base_url + "leads/id/" + this.user.id)
          .then((response) => {
            //  console.log(response);
            // this.loading = false;
            this.leads = response.data.filter(
              ({ nome_empresa }) => nome_empresa == this.orderNomes
            );
            // console.log(this.leads);
          });
      }
      if (this.orderEmail) {
        this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + sessionStorage.getItem("token");
        axios
          .get(this.$store.state.base_url + "leads/id/" + this.user.id)
          .then((response) => {
            //  console.log(response);
            // this.loading = false;
            this.leads = response.data.filter(
              ({ email }) => email == this.orderEmail
            );
            // console.log(this.leads);
          });
      }
      if (this.orderResponsavel) {
        this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + sessionStorage.getItem("token");
        axios
          .get(this.$store.state.base_url + "leads/id/" + this.user.id)
          .then((response) => {
            //  console.log(response);
            // this.loading = false;
            this.leads = response.data.filter(
              ({ nome_responsavel }) =>
                nome_responsavel == this.orderResponsavel
            );
            // console.log(this.leads);
          });
      }
      if (this.orderTelefone) {
        this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + sessionStorage.getItem("token");
        axios
          .get(this.$store.state.base_url + "leads/id/" + this.user.id)
          .then((response) => {
            //  console.log(response);
            // this.loading = false;
            this.leads = response.data.filter(
              ({ telefone }) => telefone == this.orderTelefone
            );
            // console.log(this.leads);
          });
      }
      if (this.orderWhatsapp) {
        this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + sessionStorage.getItem("token");
        axios
          .get(this.$store.state.base_url + "leads/id/" + this.user.id)
          .then((response) => {
            //  console.log(response);
            // this.loading = false;
            this.leads = response.data.filter(
              ({ whatsapp }) => whatsapp == this.orderWhatsapp
            );
            // console.log(this.leads);
          });
      }
    },
    Editar: function (id) {
      this.$router.push(`/leads/update/${id}`);
    },
    moment: function (date) {
      moment.locale("pt-br");
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    create: function () {
      this.$router.push("/leads/create");
    },
    createNewLead: function () {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .post(`${this.$store.state.base_url}leads`, {
          nome_empresa: this.nome,
          nome_responsavel: this.responsavel,
          email: this.email,
          segmento: this.segmento,
          whatsapp: this.whatsapp,
          telefone: this.telefone,
          status: 1,
          tipo_lead: 1,
          status_lead: 0,
          excluido: 0,
          id_parceiro: this.user.id,
          estado_lead: this.selectvalue,
        })
        .then((response) => {
          console.log(response);
          this.state = true;
          this.List();
          this.purge();
          this.$router.go();
        });
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("token");
      axios
        .get(this.$store.state.base_url + "leads/id/" + this.user.id)
        .then((response) => {
          //  console.log(response);
          // this.loading = false;
          this.leads = response.data.reverse();
        });
    },
    purge: function () {
      this.state = false;
    },
  },
  created() {
    this.user = JSON.parse(window.atob(sessionStorage.getItem("session")));
  },
  mounted() {
    setTimeout(() => {
      this.List();
      // console.log('console');
    }, 1000);
    // this.List();
  },
};
</script>

<style>
.td-status {
  text-align: center;
  /* color: white; */
  font-weight: bold;
}
#btn_fast {
  border: solid cyan 1px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  margin-right: 10px;
}
.modal_btn {
  display: flex;
  justify-content: end;
  margin-right: 30px;

  /* align-items: center;  */
}
#table {
  z-index: 1;
  margin-bottom: 40px;
  width: 100%;
}
.badge {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 0 0 1rem 0;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
}
</style>