<template>
  <div >
    <form id="form">
      <div class="col-md-12">
        <label for="inputEmail4" class="form-label">Email</label>
        <input type="email" class="form-control" id="inputEmail4"  v-model="email" />
      </div>
       <div class="col-md-12">
        <label for="inputEmail4" class="form-label">nome</label>
        <input type="email" class="form-control" id="inputEmail4"  v-model="nome" />
      </div>
      <div class="col-md-12">
        <label for="inputPassword4" class="form-label">Senha</label>
        <input type="password" class="form-control" id="inputPassword4"  v-model="senha" />
      </div>
      <div class="col-md-12">
        <label for="inputZip" class="form-label">Telefone</label>
        <input type="text" class="form-control" id="inputZip" />
      </div>
     

    </form>
    <div id="btn">
     <button type="button" id="button1" @click="voltar" class="btn btn-danger">Voltar</button>
     <button type="button" id="button2" @click="create" class="btn btn-success">Cadastrar</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
    name: 'FormParceiro',
     data () {
        return {
        email :'',
        senha :'',
          nome: '',
          response: '',
          error: false,
          success: false,
          tipo: 4,
          telefone: '',
          user: ""
        
        
        // lista: ''
        }
    },
    methods: {
       create() {
        this.hash = sessionStorage.getItem("token");
        axios.post(this.$store.state.base_url + "parceiros", {
          email: this.email,
          password: this.senha,
          name: this.nome,
          parceiro_id: this.user.id,
          tipo: this.tipo,
          status: 1,
        })
        .then(response => {
          if (response.data.status == "error") {
            this.response = response.data.erros;
            this.error = true;
            this.success = false;
          } else {
            this.response = response.data.message;
            this.error = false;
            this.success = true;
          }
          console.log(response)
         
        })
     
    
    },
    voltar(){
      this.$router.push('/usuarios')
    }
    },
    mounted () {
        // this.list();
  },
  created() {
      this.user = JSON.parse(sessionStorage.getItem("session"));
    }

}
</script>

<style scoped>
#form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 30px;
}

</style>