<template class="sidebar-mini layout-fixed sidebar-open">
<div>
   <Preloader/>
  <div class="wrapper">
    <Navbar />
    <section class="content">
      <div class="container-fluid">
        <Sidebar />
        <div class="content-wrapper">
          <!-- Content Header (Page header) -->
          <div class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-6">
                  <h1 class="m-0">Usuários</h1>
                </div>
                <!-- /.col -->
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Usuários</li>
                  </ol>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
          </div>
          <!-- /.content-header -->
          <Table></Table>
        </div>
      </div>
    </section>
    <TheFooter />
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/layout/Sidebar.vue";
import Navbar from "@/components/layout/Navbar.vue";
import TheFooter from "@/components/layout/Footer.vue";
import Table from "@/components/usuarios/Table.vue";
import Preloader from "@/components/layout/Preloader.vue";
// import Dashboard from "../../components/layout/Dashboard.vue";

export default {
  name: "HomeView",
  components: {
    Sidebar,
    Navbar,
    TheFooter,
    Table,
    Preloader,
  },
  created() {
    this.$forceUpdate();
  }
};
</script>
