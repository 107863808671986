<template>
  <!-- Preloader -->
  <div v-if="loading" class="preloader flex-column justify-content-center align-items-center">
    <img
      class="animation__shake"
      src="../../assets/fotos/letter-e.png"
      alt="E-commerceNetLogo"
      height="60"
      width="60"
    />
  </div>
</template>

<script>
export default {
    name: "Preloader_e",
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        setTimeout(() => (this.loading = false), 500)
    }
};
</script>

<style scoped>

</style>